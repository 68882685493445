// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require_tree .

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()


import Bulma from '@vizuaalog/bulmajs/src/core';
import Notification from '@vizuaalog/bulmajs/src/plugins/notification';
import File from '@vizuaalog/bulmajs/src/plugins/file';
import Tabs from '@vizuaalog/bulmajs/src/plugins/tabs';
import Modal from '@vizuaalog/bulmajs/src/plugins/modal';

// Import component
// import "bulmaCarousel.sass"
// import bulmaCarousel from '~bulma-carousel/dist/js/bulma-carousel.min.js';
// import bulmaCarousel from 'bulma-carousel/dist/js/bulma-carousel.min.js';

//@import 'bulma';
//@import '~bulma-switch';
//import 'bulma'
//import "bulma-switch" from  "~bulma-switch"

// import bulmaSwitch from '~bulma-switch/dist/bulma-switch.min.js'

// require("@rails/ujs").start()
require("jquery")
require("jquery-ui")

// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

$(document).on("turbolinks:load", function() {
    Bulma.traverseDOM();
});

$(document).on('click', '#activity-delete', function() {
    let activity_id = $(this).data('activity-id');
    let url = '/activities/delete/' + activity_id;
    $.post(url)
        .done(function( data ) {
            Bulma.create('notification', {
                isDismissable: true,
                dismissInterval: 4000,
                color: 'success',
                body: 'Actividad eliminada.',
                parent: document.getElementById('notification-area'),
            }).show();
        });
});

$(document).on('click', '.activity-file-delete', function() {
    let file_id = $(this).data('activity-file-id');
    let activity_id = $(this).data('activity-id');
    $.post( "/activity_files/delete/" + file_id )
        .done(function( data ) {

            let url = '/activities/files_ui/' + activity_id;
            $.get(url, function(data) {
                $('#activity-file-ui').html(data);
            });

            Bulma.create('notification', {
                isDismissable: true,
                dismissInterval: 4000,
                color: 'success',
                body: 'Archivo eliminado.',
                parent: document.getElementById('notification-area'),
            }).show();

        });
});

$(document).on('ajax:success', '#activity-upload-file-form', function(evt, data, status, xhr){
    let activity_id = $('#activity_file_activity_id').val();
    let url = '/activities/files_ui/' + activity_id;
    $.get(url, function(data) {
        $('#activity-file-ui').html(data);
    });

    Bulma.create('notification', {
        isDismissable: true,
        dismissInterval: 4000,
        color: 'success',
        body: 'Archivo agregado.',
        parent: document.getElementById('notification-area'),
    }).show();

});

$(document).on('ajax:error', '#activity-upload-file-form', function(evt, xhr, status, error) {
    Bulma.create('notification', {
        isDismissable: true,
        dismissInterval: 4000,
        color: 'error',
        body: 'Archivo no pudo ser agregado.',
        parent: document.getElementById('notification-area'),
    }).show();
});

window.showNotice = function(msg) {
    Bulma.create('notification', {
        isDismissable: true,
        dismissInterval: 4000,
        color: 'success',
        body: msg,
        parent: document.getElementById('notification-area'),
    }).show();
};

$(document).on('click', '#activity-type-member-add', function() {
    let new_member_id = $('#new-member-id').val();
    let activity_type_id = $(this).data('activity-type-id');
    $.post( "/activity_type_members/",  { activity_type_id: activity_type_id, member_id: new_member_id})
        .done(function( data ) {
            /*
            var url = '/participantes/ui/' + product_class + '/' + product_id
            $.get(url, function(data) {
                $('#product-participants-ui').html(data);
                $('#participant_author_is').hide();
            });
            */
            Bulma.create('notification', {
                isDismissable: true,
                dismissInterval: 4000,
                color: 'success',
                body: 'Supervisor agregado.',
                parent: document.getElementById('notification-area'),
            }).show();

        });
});

$(document).on('click', '#new-tutor-add', function() {
    let new_tutor_id = $('#new-tutor-id').val();
    let activity_id = $(this).data('activity-id');
    $.post( "/tutors/",  { activity_id: activity_id, staff_id: new_tutor_id})
        .done(function( data ) {

            let url = '/tutors/tutors_ui/' + activity_id;
            $.get(url, function(data) {
                $('#activity-tutors-ui').html(data);
            });

            Bulma.create('notification', {
                isDismissable: true,
                dismissInterval: 4000,
                color: 'success',
                body: 'Colaborador agregado.',
                parent: document.getElementById('notification-area'),
            }).show();

        });
});

$(document).on('click', '#activity-tutor-delete', function() {
    let tutor_id = $(this).data('tutor-id');
    let activity_id = $(this).data('activity-id');
    let url = "/tutors/destroy/" + tutor_id;
    $.post(url)
        .done(function( data ) {

            let url = '/tutors/tutors_ui/' + activity_id;
            $.get(url, function(data) {
                $('#activity-tutors-ui').html(data);
            });

            Bulma.create('notification', {
                isDismissable: true,
                dismissInterval: 4000,
                color: 'success',
                body: 'Colaborador eliminado.',
                parent: document.getElementById('notification-area'),
            }).show();

        });
});

$(document).on('change', '#activity_activity_type_id', function() {
    let id_selected = $(this).val();
    console.log('activity_type_selected_id >> ', id_selected);

    //$('#activity_laboratory_field').css("display", (id_selected == 5) ? "block" : "none");
    //$('#activity_journal_field').css("display", (id_selected == 8) ? "block" : "none");

    $('#activity_laboratory_field').prop("disabled", id_selected != 5);
    $('#activity_journal_field').prop("disabled", id_selected != 8);

    $("#select_members_id").prop("disabled", id_selected == '');
    let url = '/activity_type_members/members_by_type/' + id_selected;
    $.get(url, function(data) {
        const members = data["members"];
        $("#activity_member_id").empty();
        $("#activity_member_id").append('<option>-- Seleccionar supervisor --</option>');
        for(var i = 0; i < members.length; i++){
            $("#activity_member_id").append('<option value="' + members[i][0] + '">'  +  members[i][2] + '</option>');
        }
    });
});

$(document).on('click', '.modify-status-class', function() {
    let activity_id = $(this).data('activity-id');
    let status_selected = $(this).data('activity-status');
    console.log(activity_id + ' > ' + status_selected);

    $.post("/activities/set_status/" + activity_id + "/" + status_selected) //,  { id: activity_id, status: status_selected})
        .done(function (data) {
            console.log("Done it");
        });
    /*
    if (status_selected == 6) { // FINISHED
        modalFinished = Bulma.create('modal', {
            element: document.querySelector('#modal-finished-id')
        });
        modalFinished.open();
    } else {
        $.post("/activities/set_status/" + activity_id + "/" + status_selected) //,  { id: activity_id, status: status_selected})
            .done(function (data) {
                console.log("Done it");
            });
    }
    */
});

$(document).on('change', '#filter-who-id', function() {
    let who_selected = $(this).val();
    let status_selected = $('#filter-status-id').val();
    status_selected = status_selected ? +status_selected : 0;
    $.get('/activities/table_activities/' + who_selected + '/' + status_selected, function(data) {
        $('#table_activities_id').html(data);
    });
});

$(document).on('change', '#filter-status-id', function() {
    let who_selected = $('#filter-who-id').val();
    let status_selected = $(this).val();
    status_selected = status_selected ? +status_selected : 0;
    $.get('/activities/table_activities/' + who_selected + '/' + status_selected, function(data) {
        $('#table_activities_id').html(data);
    });
});


